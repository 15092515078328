<script lang="ts">
	import 'uno.css';
	import '../font.css';
	import '../app.css';
	import { colorInit } from '$store/darkMode';
	import { onMount } from 'svelte';
	import Toasts from '$components/atoms/toast/Toasts.svelte';
	import * as Sentry from '@sentry/svelte';
	import { PUBLIC_NODE_ENV } from '$env/static/public';

	onMount(() => {
		colorInit();
		if (PUBLIC_NODE_ENV === 'production') {
			Sentry.init({
				environment: 'production',
				dsn: 'https://5847fe81d335a852947769afda998cb3@o4504565053063168.ingest.us.sentry.io/4507644514336768',
				integrations: [
					Sentry.browserTracingIntegration(),
					Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false })
				],
				tracesSampleRate: 1.0,
				replaysSessionSampleRate: 1.0,
				replaysOnErrorSampleRate: 1.0
			});
		}
	});
</script>

<main class="pt-15 min-h-screen w-full" data-sveltekit-preload-data>
	<slot />
</main>
<Toasts />
